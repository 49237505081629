import {
  ControlButton, ManualCheckbox,
  PiWizardFieldPath, PiWizardFieldTypeEnum,
  PiWizardNavigationButtonEnum, PiWizardPage,
  WizardNavigationButton, PiWizardStep
} from "@/components/Wizard/WizardNew/types";
import { applyAction, reactiveSet, resolveCondition } from "@/components/Wizard/WizardNew/logic";

import { Component, Emit, Prop } from "vue-property-decorator";
import Vue from "vue";
import { cloneDeep, get } from "lodash";

@Component({})
export default class PiWizardStepMixin extends Vue {
  @Prop() page!: PiWizardPage

  @Prop() actions!: PiWizardStep['actions']

  @Prop() conditions!: PiWizardStep['conditions']

  @Prop() stepModel!: any

  @Emit()
  navigationButtonClick(button: WizardNavigationButton) {
    return { button, stepModel: this.stepModelCopy }
  }

  @Emit()
  controlButtonClick(button: ControlButton) {
    return { button, stepModel: this.stepModelCopy }
  }

  localStateForManualFields: Record<string, any> = {}

  stepModelCopy: any | null = null

  get title() {
    return this.page.title
  }

  get headerDescription() {
    return this.page.headerDescription
  }

  get footerDescription() {
    return this.page.footerDescription
  }

  get fields() {
    return this.page.fields
  }

  get navigation() {
    return this.page.navigation
  }

  get condition() {
    return this.page.condition
  }

  getLocalFieldValue(fieldPath: PiWizardFieldPath) {
    const path = this.getFieldLocalKey(fieldPath)

    return get(this.localStateForManualFields, path)
  }

  navigationButtonClickProxy(button: WizardNavigationButton) {
    if (this.fields) {
      if (button.type === PiWizardNavigationButtonEnum.NextButton) {
        this.fields.forEach(field => {
          if (field.type === PiWizardFieldTypeEnum.ManualCheckbox) {
            if (field.checkboxStateActions && this.actions) {
              const checkboxAction = field.checkboxStateActions[this.getLocalFieldValue(field.localFieldPath) as keyof ManualCheckbox['checkboxStateActions']]
              const action = this.actions[checkboxAction]

              this.stepModelCopy = applyAction(action, this.stepModelCopy)
            }
          } else if (field.type === PiWizardFieldTypeEnum.ManualChips || field.type === PiWizardFieldTypeEnum.ManualSelect) {
            this.stepModelCopy = reactiveSet(this.stepModelCopy, this.getFieldLocalKey(field.localFieldPath), this.getLocalFieldValue(field.localFieldPath))
          } else if (field.type === PiWizardFieldTypeEnum.ControlButton) {
            if (field.action && this.actions) {
              this.stepModelCopy = applyAction(field.action, this.stepModelCopy)
            }
          }
        })
      }
    }

    this.navigationButtonClick(button)
  }

  getFieldLocalKey(localFieldPath: PiWizardFieldPath) {
    if (localFieldPath.model) {
      return `${ localFieldPath?.model }.${ localFieldPath?.key }`
    }

    return localFieldPath.key!
  }

  prepareLocalFields() {
    if (this.fields) {
      this.fields.forEach(f => {
        if (f.type === PiWizardFieldTypeEnum.ManualCheckbox || f.type === PiWizardFieldTypeEnum.ManualChips || f.type === PiWizardFieldTypeEnum.ManualSelect) {
          this.localStateForManualFields = reactiveSet(this.localStateForManualFields, this.getFieldLocalKey(f.localFieldPath), f.initValue(this.stepModelCopy))
        }
      })
    }
  }

  prepareCurrentStepData() {
    this.stepModelCopy = cloneDeep(this.stepModel)

    this.prepareLocalFields()
  }

  created() {
    if (this.conditions && this.condition) {
      if (!resolveCondition(this.conditions[this.condition], this.stepModel)) {
        if (this.page.navigation) {
          const next = this.page.navigation.find(b => b.type === PiWizardNavigationButtonEnum.NextButton)

          if (next) {
            this.navigationButtonClick(next)
          }
        }
      } else {
        this.prepareCurrentStepData()
      }
    } else {
      this.prepareCurrentStepData()
    }
  }
}
