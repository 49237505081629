































































































import PiWizardFooter from "@/components/Wizard/WizardNew/components/PiWizardFooter.vue";
import { PiWizardFieldTypeEnum } from "@/components/Wizard/WizardNew/types";
import PiWizardStepMixin from "@/components/Wizard/WizardNew/components/PiWizardStepMixin";

import CheckboxInput from 'piramis-base-components/src/components/Pi/fields/CheckboxInput/CheckboxInput.vue';
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import ChipsInput from "piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";

import { Component, Mixins } from 'vue-property-decorator';

@Component({
  data() {
    return {
      PiWizardFieldTypeEnum
    }
  },
  components: {
    PiWizardFooter,
    CheckboxInput,
    ChipsInput,
    SelectInput
  }
})
export default class PiBaseLayout extends Mixins(ModelSetter, PiWizardStepMixin) {
}
