var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pi-base-layout flex flex-col w-full gap-5"},[_c('div',{staticClass:"flex-1 w-full md:w-1/2 mx-auto flex flex-col justify-center items-center"},[_c('div',{staticClass:"pi-base-layout__header text-center"},[(_vm.title)?_c('h3',{staticClass:"title"},[_c('b',{domProps:{"innerHTML":_vm._s(_vm.title)}})]):_vm._e(),(_vm.headerDescription)?_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.headerDescription)}}):_vm._e()]),(_vm.fields && _vm.fields.length)?_c('div',{staticClass:"pi-base-layout__fields my-10 flex flex-col gap-2 w-full"},[_vm._l((_vm.fields),function(field,index){return [(field.type === _vm.PiWizardFieldTypeEnum.ControlButton)?_c('a-button',{key:index,class:[].concat( field.classNames ),attrs:{"type":field.antType},on:{"click":function($event){return _vm.controlButtonClick(field)}}},[_vm._v(" "+_vm._s(field.title)+" ")]):_vm._e(),(field.type === _vm.PiWizardFieldTypeEnum.ManualChips)?_c('chips-input',{key:index,staticClass:"mt-0 mb-0",attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.localStateForManualFields[field.localFieldPath.model],
              'key': field.localFieldPath.key,
              'prefix': field.localFieldPath.prefix
            }
          }}}):_vm._e(),(field.type === _vm.PiWizardFieldTypeEnum.ManualSelect)?_c('select-input',{key:index,staticClass:"mt-0 mb-0 w-full",attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': field.localFieldPath.model ? _vm.localStateForManualFields[field.localFieldPath.model] : _vm.localStateForManualFields,
              'key': field.localFieldPath.key,
              'prefix': field.localFieldPath.prefix,
              'options': field.options,
              'clearable': field.clearable,
              'multiple': field.multiple,
            }
          }}}):_vm._e(),(field.type === _vm.PiWizardFieldTypeEnum.ManualCheckbox)?_c('checkbox-input',{key:index,staticClass:"mt-0 mb-0",attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.localStateForManualFields,
              'key': _vm.getFieldLocalKey(field.localFieldPath),
              'prefix': field.localFieldPath.prefix
            }
          }}}):_vm._e()]})],2):_vm._e(),(_vm.footerDescription)?_c('p',{staticClass:"pi-base-layout__footer-description text-center mb-0",domProps:{"innerHTML":_vm._s(_vm.footerDescription)}}):_vm._e()]),_c('pi-wizard-footer',{staticClass:"pi-base-layout__footer",attrs:{"navigation":_vm.navigation},on:{"navigation-button-click":_vm.navigationButtonClickProxy}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }